@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tiro+Devanagari+Marathi&display=swap');

* {
    outline: none;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
}

[data-lang=telugu] {
    font-family: 'Anek Telugu', sans-serif;
}

[data-lang=hindi] {
    font-family: 'Tiro Devanagari Marathi', serif;
}

body {
    margin: 0;
}



input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    padding-right: 10px;
}

:root {
    --theme-bg-color: rgba(16 18 27 / 40%);
    --theme-bg-color2: rgba(16 18 27 / 20%);
    --border-color: rgba(113 119 144 / 25%);
    --theme-color: #f9fafb;
    --inactive-color: rgb(113 119 144 / 78%);
    --body-font: "Poppins", sans-serif;
    --hover-menu-bg: rgba(12 15 25 / 30%);
    --content-title-color: #999ba5;
    --content-bg: rgb(146 151 179 / 13%);
    --button-inactive: rgb(249 250 251 / 55%);
    --dropdown-bg: #21242d;
    --dropdown-hover: rgb(42 46 60);
    --popup-bg: rgb(22 25 37);
    --search-bg: #14162b;
    --overlay-bg: rgba(36, 39, 59, 0.3);
    --scrollbar-bg: rgb(1 2 3 / 40%);
}

.light-mode {
    --theme-bg-color: rgb(255 255 255 / 31%);
    --theme-color: #3c3a3a;
    --inactive-color: #333333;
    --button-inactive: #3c3a3a;
    --search-bg: rgb(255 255 255 / 31%);
    --dropdown-bg: #f7f7f7;
    --overlay-bg: rgb(255 255 255 / 30%);
    --dropdown-hover: rgb(236 236 236);
    --border-color: rgb(255 255 255 / 35%);
    --popup-bg: rgb(255 255 255);
    --hover-menu-bg: rgba(255 255 255 / 35%);
    --scrollbar-bg: rgb(255 253 253 / 57%);
    --content-title-color: --theme-color;
}



#root {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--body-font);
    background-image: url(./assets/images/bg.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.globalhead {
    font-size: 30px;
    font-weight: 100;
    color: "#fff";
}

@media screen and (max-width: 1200px) {
    #root {
        padding: 0px;
        height: "100%";
    }
}

.glassShadow {
    box-shadow: 0 2px 1px hsla(0, 0%, 100%, 0.5) inset, 0 -2px 1px hsla(250, 70%, 5%, 0.3) inset,
        0 -2px 6px hsla(0, 0%, 100%, 0.25);
}

@media screen and (max-width: 480px) {
    #root {
        padding: 0;
    }
}

.video-bg {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.video-bg video {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

img {
    max-width: 100%;
}

.dark-light {
    position: fixed;
    bottom: 50px;
    right: 30px;
    background-color: var(--dropdown-bg);
    box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.2);
    padding: 8px;
    border-radius: 50%;
    z-index: 3;
    // cursor: pointer;
}

.dark-light svg {
    width: 24px;
    flex-shrink: 0;
    fill: #ffce45;
    stroke: #ffce45;
    transition: 0.5s;
}

.light-mode .dark-light svg {
    fill: transparent;
    stroke: var(--theme-color);
}

.light-mode .profile-img {
    border: 2px solid var(--theme-bg-color);
}

.light-mode .content-section ul {
    background-color: var(--theme-bg-color);
}

.light-mode .pop-up__title {
    border-color: var(--theme-color);
}

.light-mode .dropdown.is-active ul {
    background-color: rgba(255, 255, 255, 0.94);
}

#root.light-mode:before,
#root.light-mode .video-bg:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.45) 100%);
    -webkit-backdrop-filter: saturate(3);
    backdrop-filter: saturate(3);
}

@media screen and (max-width: 1200px) {
    .content-wrapper {
        padding: 15px 15px !important;
    }
}

.header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 58px;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    padding: 0 20px;
    padding-left: 10px;
    padding-right: 30px;
    white-space: nowrap;
}

@media screen and (max-width: 480px) {
    .header {
        padding: 0 16px;
    }
}

.header-menu {
    display: flex;
    align-items: center;
}

.header-menu a {
    padding: 20px 30px;
    text-decoration: none;
    color: var(--inactive-color);
    border-bottom: 2px solid transparent;
    transition: 0.3s;
}

@media screen and (max-width: 610px) {
    .header-menu a:not(.main-header-link) {
        display: none;
    }
}

.header-menu a.is-active,
.header-menu a:hover {
    color: var(--theme-color);
    border-bottom: 2px solid var(--theme-color);
}

.notify {
    position: relative;
}

.notify:before {
    content: "";
    position: absolute;
    background-color: #3a6df0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    right: 20px;
    top: 16px;
}

@media screen and (max-width: 1055px) {
    .notify {
        display: none;
    }
}

.menu-circle {
    width: 15px;
    height: 15px;
    background-color: #f96057;
    border-radius: 50%;
    box-shadow: 24px 0 0 0 #f8ce52, 48px 0 0 0 #5fcf65;
    margin-right: 195px;
    margin-left: 15px;
    flex-shrink: 0;
}

@media screen and (max-width: 945px) {
    .menu-circle {
        display: none;
    }
}

.search-bar {
    height: 40px;
    display: flex;
    width: 100%;
    max-width: 400px;
    padding-left: 16px;
    border-radius: 4px;
}

.textinput {
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    border: none;
    border-radius: 4px;
    font-family: var(--body-font);
    font-size: 15px;
    font-weight: 500;
    padding: 0 20px 0 10px;
    box-shadow: 0 0 0 2px rgba(134, 140, 160, 0.02);
    color: var(--theme-color);

    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    resize: none;
    background-color: transparent;
    padding: 10px;
    font-size: 20px;
    border: 1px solid rgba(75, 85, 99, 0.8);
    border-radius: 14;
    color: lightgray;
}

.textinput {
    border: none;
    background-color: var(--search-bg);
    border-radius: 4px;
    font-family: var(--body-font);
    font-size: 15px;
    font-weight: 500;
    padding: 0 20px 0 40px;
    box-shadow: 0 0 0 2px rgba(134, 140, 160, 0.02);
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23717790c7'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 16px 48%;
    color: var(--theme-color);
}

.search-bar input::-moz-placeholder {
    font-family: var(--body-font);
    color: var(--inactive-color);
    font-size: 15px;
    font-weight: 500;
}

.search-bar input:-ms-input-placeholder {
    font-family: var(--body-font);
    color: var(--inactive-color);
    font-size: 15px;
    font-weight: 500;
}

.search-bar input::placeholder {
    font-family: var(--body-font);
    color: var(--inactive-color);
    font-size: 15px;
    font-weight: 500;
}

.header-profile {
    display: flex;
    align-items: center;
    // padding: 0 6px 0 40px;
    margin-left: auto;
    flex-shrink: 0;
}

.header-profile svg {
    width: 22px;
    color: #f9fafb;
    flex-shrink: 0;
}

.notification {
    position: relative;
}

.notification-number {
    position: absolute;
    background-color: #3a6df0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    right: -6px;
    top: -6px;
}

.notification+svg {
    margin-left: 22px;
}

@media screen and (max-width: 945px) {
    .notification+svg {
        display: none;
    }
}

.profile-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    border: 2px solid var(--theme-color);
    margin-left: 22px;
}

.profile-img-disc {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    border: 2px solid var(--theme-color);
    transition: 3s ease-in;
    margin-left: 10px;
    margin-right: 10px;
    animation: spin 10s linear infinite;
}

.profile-img-disc-stop {
    animation-play-state: paused;
}

.wide .header-menu,
.wide .header-profile {
    display: none;
}

.wide .search-bar {
    max-width: 600px;
    margin: auto;
    transition: 0.4s;
    box-shadow: 0 0 0 1px var(--border-color);
    padding-left: 0;
}

.wide .menu-circle {
    margin-right: 0;
}

.wrapper {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}

.left-side {
    flex-basis: 240px;
    border-right: 1px solid var(--border-color);
    padding: 10px;
    overflow: auto;
    flex-shrink: 0;
    transition: 1s ease-in;
}

.hidden {
    max-height: 0;
    box-shadow: none;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
}

.visible {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
}

@media screen and (max-width: 945px) {
    .left-side {
        display: none;
    }
}

.side-title {
    color: #fff9;
    margin-bottom: 14px;
}

.side-menu {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}

.side-menu a {
    text-decoration: none;
    color: var(--theme-color);
    display: flex;
    align-items: center;
    font-weight: 400;
    padding: 10px;
    margin-top: 5px;
    font-size: 14px;
    border-radius: 6px;
    transition: 0.3s;
}

.side-menu a:hover {
    background-color: #fff1;
}

.active-side-menu {
    background-color: var(--hover-menu-bg);
}

.updates {
    position: relative;
    top: 0;
    right: 0;
    margin-left: auto;
    width: 18px;
    height: 18px;
    font-size: 11px;
}

.main-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    height: 58px;
    flex-shrink: 0;
}

.main-header .header-menu {
    margin-left: 150px;
}

@media screen and (max-width: 1055px) {
    .main-header .header-menu {
        margin: auto;
    }
}

.main-header .header-menu a {
    padding: 20px 24px;
}

.main-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.menu-link-main {
    text-decoration: none;
    color: var(--theme-color);
    padding: 0 30px;
}

@media screen and (max-width: 1055px) {
    .menu-link-main {
        display: none;
    }
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    color: var(--theme-color);
    padding: 20px 20px;
    height: 100%;
    overflow: auto;
    background-color: var(--theme-bg-color2);
}

.content-wrapper-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"),
        linear-gradient(to right top,
            #cf4af3,
            #e73bd7,
            #f631bc,
            #fd31a2,
            #ff3a8b,
            #ff4b78,
            #ff5e68,
            #ff705c,
            #ff8c51,
            #ffaa49,
            #ffc848,
            #ffe652);
    border-radius: 14px;
    padding: 20px 40px;
}

@media screen and (max-width: 415px) {
    .content-wrapper-header {
        padding: 20px;
    }
}

.content-wrapper.overlay {
    pointer-events: none;
    transition: 0.3s;
    background-color: var(--overlay-bg);
}

.overlay-app {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: all;
    background-color: rgba(36, 39, 59, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
}

.overlay-app.is-active {
    visibility: visible;
    opacity: 1;
}

.img-content {
    font-weight: 500;
    font-size: 17px;
    display: flex;
    align-items: center;
    margin: 0;
}

.img-content svg {
    width: 28px;
    margin-right: 14px;
}

.content-text {
    font-weight: 400;
    font-size: 14px;
    margin-top: 16px;
    line-height: 1.7em;
    color: #ebecec;
    display: -webkit-box;
    // line-clamp: 4;
    // -webkit-line-clamp: 4;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    // text-overflow: ellipsis;
}

.content-wrapper-context {
    max-width: 700px;
}

.content-button {
    background-color: #3a6df0;
    border: none;
    padding: 8px 26px;
    color: #fff;
    border-radius: 20px;
    margin-top: 10px;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
}

.content-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.content-section-title {
    color: var(--content-title-color);
    margin-bottom: 14px;
}

.content-section .ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    background-color: var(--content-bg);
    padding-left: 0;
    margin: 0;
    border-radius: 14px;
    border: 1px solid #fff1;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    // cursor: pointer;
}



.content-section2 ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    background-color: var(--content-bg);
    padding-left: 0;
    margin: 0;
    border: 1px solid var(--theme-bg-color);
    // cursor: pointer;
}

.content-section .ul .li {
    list-style: none;
    padding: 10px 18px;
    display: flex;
    align-items: center;
    font-size: 16px;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    transition: 0.3s;
}

.li-active {
    background-color: var(--theme-bg-color);
}

.content-section .ul .li:hover {
    background-color: var(--theme-bg-color);
}

.content-section .ul .li:hover:first-child {
    border-radius: 13px 13px 0 0;
}

.content-section .ul .li:hover:last-child {
    border-radius: 0 0 13px 13px;
}

.content-section .ul .li+li {
    border-top: 1px solid var(--border-color);
}

.content-section .ul img {
    width: 28px;
    height: 28px;
    border-radius: 6px;
    margin-right: 16px;
    flex-shrink: 0;
}

.products {
    display: flex;
    align-items: center;
    width: 150px;
}

@media screen and (max-width: 480px) {
    .products {
        width: 120px;
    }
}

.status {
    margin-left: auto;
    width: 140px;
    font-size: 15px;
    position: relative;
}

@media screen and (max-width: 700px) {
    .status {
        display: none;
    }
}

.status-circle {
    width: 6px;
    height: 6px;
    background-color: #396df0;
    position: absolute;
    border-radius: 50%;
    top: 4px;
    left: -20px;
}

.status-circle.green {
    background-color: #3bf083;
}

.status-button {
    font-size: 15px;
    margin-top: 0;
    padding: 6px 24px;
}

@media screen and (max-width: 390px) {
    .status-button {
        padding: 6px 14px;
    }
}

.status-button.open {
    background: none;
    color: var(--button-inactive);
    border: 1px solid var(--button-inactive);
}

.status-button:not(.open):hover {
    color: #fff;
    border-color: #fff;
}

.content-button:not(.open):hover {
    background: #1e59f1;
}

.menu {
    width: 5px;
    height: 5px;
    background-color: var(--button-inactive);
    border-radius: 50%;
    box-shadow: 7px 0 0 0 var(--button-inactive), 14px 0 0 0 var(--button-inactive);
    margin: 0 12px;
}

@media screen and (max-width: 415px) {
    .adobe-product .menu {
        display: none;
    }
}

.button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 187px;
    margin-left: auto;
}

@media screen and (max-width: 480px) {
    .button-wrapper {
        width: auto;
    }
}

.pop-up {
    position: absolute;
    padding: 30px 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.4);
    transition: all 0.3s;
    z-index: 10;
    background-color: var(--popup-bg);
    width: 500px;
    visibility: hidden;
    opacity: 0;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    white-space: normal;
}

@media screen and (max-width: 570px) {
    .pop-up {
        width: 100%;
    }
}

.pop-up.visible {
    visibility: visible;
    opacity: 1;
}

.pop-up__title {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pop-up__subtitle {
    white-space: normal;
    margin: 20px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8em;
}

.pop-up__subtitle a {
    color: var(--theme-color);
}

.content-button-wrapper .content-button.status-button.open.close {
    width: auto;
}

.content-section .close {
    margin-right: 0;
    width: 24px;
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
}

.checkbox-wrapper+.checkbox-wrapper {
    margin: 20px 0 40px;
}

.checkbox {
    display: none;
}

.checkbox+label {
    display: flex;
    align-items: center;
}

.checkbox+label:before {
    content: "";
    margin-right: 10px;
    width: 15px;
    height: 15px;
    border: 1px solid var(--theme-color);
    border-radius: 4px;
    cursor: pointer;
    flex-shrink: 0;
}

.checkbox:checked+label:before {
    background-color: #3a6df0;
    border-color: #3a6df0;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' className='feather feather-check'%3e%3cpath d='M20 6L9 17l-5-5'/%3e%3c/svg%3e");
    background-position: 50%;
    background-size: 12px;
    background-repeat: no-repeat;
}

.content-button-wrapper {
    margin-top: auto;
    margin-left: auto;
}

.content-button-wrapper .open {
    margin-right: 8px;
}

.apps-card {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% + 20px);
}

.app-card {
    display: flex;
    flex-direction: column;
    width: calc(25% - 20px);
    font-size: 16px;
    background-color: var(--content-bg);
    border-radius: 14px;
    border: 1px solid var(--theme-bg-color);
    padding: 20px;
    // cursor: pointer;
    transition: 0.3s ease;
    // min-height: 150px;
}

.app-card:hover {
    transform: scale(1.02);
    background-color: var(--theme-bg-color);
}

.app-card img {
    width: 28px;
    height: 28px;
    border-radius: 6px;
    margin-right: 12px;
    flex-shrink: 0;
}

.app-card+.app-card {
    margin-left: 20px;
}

.app-card span {
    display: flex;
    align-items: center;
}

.app-card__subtext {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6em;
    margin-top: 20px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
}

.app-card-buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-top: 16px;
}

@media screen and (max-width: 945px) {
    .app-card {
        width: calc(100% - 20px);
        margin-top: 20px;
    }

    .app-card:nth-child(2),
    .app-card:nth-child(3),
    .app-card:nth-child(4) {
        // margin-top: 20px;
        margin-left: 0px;
    }
}

::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--scrollbar-bg);
    border-radius: 10px;
}

.dock-container {
    position: fixed;
    bottom: 0;
    text-align: center;
    right: 20%;
    left: 20%;
    width: 60%;
    border-radius: 10px 10px 0 0;
    background-color: var(--theme-bg-color);
    --size: 6rem;
    --transition-duration: 500ms;
    --transition-easing: ease-out;
}

.dock-container ul {
    padding-inline-start: 0;
}

.dock-container li {
    list-style-type: none;
    display: inline-block;
    position: relative;
}

.dock-container li img {
    width: 50px;
    height: 50px;
    -webkit-box-reflect: below 2px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0.7, transparent), to(rgba(255, 255, 255, 0.5)));
    transition: all 0.3s;
    transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
    -webkit-transition: all 0.3s;
    // box-shadow: 0 2px 1px hsla(0, 0%, 100%, 0.5) inset,
    // 0 -2px 1px hsla(250, 70%, 5%, 0.3) inset,
    // 0 -2px 6px hsla(0, 0%, 100%, 0.25);
    // border-radius: 10px;
    margin: 2px;
}

.dock-container li:hover img {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    margin: 0 1.5em;
}

.dock-container li:hover+li img,
.dock-container li.prev img {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin: 0 1em;
}

.dock-container li span {
    display: none;
    position: absolute;
    bottom: 100px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 4px 0;
    border-radius: 12px;
    z-index: 10000;
}

.dock-container li:hover span {
    display: block;
    color: #fff;
}

.bar {
    position: relative;
    background-color: rgba(255, 255, 255, 0.06);
    border: 1px solid rgba(255, 255, 255, 0.06);
    padding: 7px 10px;
    border-radius: 20px;
    width: 150px;
}

.bar div {
    position: relative;
    width: 100px;
    height: 5px;
    border-radius: 10px;
    background-color: #badefc;
    border: 1px solid #1c97fc;
    box-shadow: 0 0 5px rgba(28, 151, 252, 0.6);
}

.BrainhubCarousel__customArrows {
    svg {
        box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    }

    .button {
        color: #fff;
        margin: 40px auto;
        background-color: rgba(255, 255, 255, 0.06);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 15px;
        height: 150px;
        cursor: pointer;
        transition: 0.3s;
        white-space: nowrap;
        width: 18px;
    }

    .scrollbutton {
        color: #fff;
        margin: 40px auto;
        background-color: rgba(255, 255, 255, 0.06);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 15px;
        height: 150px;
        cursor: pointer;
        transition: 0.3s;
        white-space: nowrap;
        width: 18px;
    }
}

.copyto {
    position: absolute;
    right: 0;
    padding-right: 10px;
    color: "blue";
}

.link {
    color: white;
    text-decoration: none;

    &:hover {
        color: skyblue;
    }
}

//////////////////////////////////////////////////

.screen {
    position: relative;
    border-radius: 14px;
    border: 2px solid #fff1;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    padding: 20px;
}

.screen:after {
    content: "";
    display: block;
    position: absolute;
    background-color: #9297b321;
    top: 0;
    left: 0px;
    right: 0px;
    bottom: 0;
    border-radius: 15px;
    // box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
    z-index: -1;
}

.screen-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #4d4d4f;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.screen-header-left {
    margin-right: auto;
}

.screen-header-button {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 3px;
    border-radius: 8px;
    background: white;
}

.screen-header-button.close {
    background: #ed1c6f;
}

.screen-header-button.maximize {
    background: #e8e925;
}

.screen-header-button.minimize {
    background: #74c54f;
}

.screen-header-right {
    display: flex;
}

.screen-header-ellipsis {
    width: 3px;
    height: 3px;
    margin-left: 2px;
    border-radius: 8px;
    background: #999;
}

.screen-body {
    // display: flex;
}

.screen-body-item {
    // flex: 1;
    // padding: 50px;
}

.screen-body-item.left {
    // display: flex;
    // flex-direction: column;
}

.app-title {
    // display: flex;
    // flex-direction: column;
    // position: relative;
    // color: #ea1d6f;
    // font-size: 26px;
}

.app-contact {
    margin-top: auto;
    font-size: 8px;
    color: #888;
}

.app-form-group {
    margin-bottom: 15px;
}

.app-form-group.message {
    margin-top: 40px;
}

.app-form-group.buttons {
    margin-bottom: 0;
    text-align: right;
}

.app-form-control {
    width: 100%;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px solid #6665;
    color: #ddd;
    font-size: 14px;
    outline: none;
    transition: border-color 0.2s;
}

.app-form-control::placeholder {
    color: #fff9;
}

.app-form-control:focus {
    border-bottom-color: #ddd;
}

.credits {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: #ffa4bd;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    font-weight: normal;
}

iframe {
    main {
        background-color: red;
    }
}

//////////////////////Playerr

.playbutton {
    height: 100px;
    width: 100px;
    z-index: 1000;
    border-radius: 50%;
    background-color: #ff705c;
    color: #fff;
    place-content: center;
    animation: grow 2s;

    &:hover {
        box-shadow: 0px 0px 30px lightgreen;
        transition: 1s ease-in;
    }
}

.playbutton_small {
    height: 100px;
    width: 100px;
    z-index: 1000;
    border-radius: 50%;
    background-color: #ff705c;
    color: #fff;
    place-content: center;
}

.active_play_button {
    background-color: lightgreen;
    animation: growSmall 1s infinite;
}

.playbutton_rounded {
    background-color: lightgreen;
    animation: shrink 2s;
    box-shadow: none;
    transition: 1s ease-out;
}

.active_button {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #ff705c;
    color: #fff;
    box-shadow: 0px 0px 30px #ff705c;
    animation: none;
    padding: 5px;
}

.hover_button {
    &:hover {
        border-radius: 10px;
        background-color: #ff705c;
        color: #fff;
        padding: 2px;
        box-shadow: 0px 0px 30px #ff705c;
        animation: none;
    }
}

@keyframes shrink {
    from {
        box-shadow: 0px 0px 50px #ff705c;
        background-color: #ff705c;
    }

    to {
        box-shadow: 0px 0px 0px lightgreen;
        background-color: lightgreen;
    }
}

@keyframes grow {
    from {
        box-shadow: 0px 0px 0px lightgreen;
    }

    to {
        box-shadow: 0px 0px 60px #ff705c;
    }
}

@keyframes growSmall {
    from {
        box-shadow: 0px 0px 5px lightgreen;
    }

    to {
        box-shadow: 0px 0px 20px #fff;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

//////////////////////Songs List

.songs_list ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    background-color: var(--content-bg);
    padding-left: 0;
    margin: 0;
    cursor: pointer;
}

.songs_list ul li {
    list-style: none;
    padding: 10px 18px;
    display: flex;
    align-items: center;
    font-size: 16px;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    transition: 0.3s;
}

.songs_list ul li:hover {
    background-color: var(--theme-bg-color);
}

.songs_list ul .active {
    background-color: var(--theme-bg-color);
}

.songs_list ul li+li {
    border-top: 1px solid var(--border-color);
}

.songs_list ul img {
    width: 28px;
    height: 28px;
    border-radius: 6px;
    margin-right: 16px;
    flex-shrink: 0;
}

.gradienttext {
    background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
    background-blend-mode: screen, overlay, hard-light, color-burn, color-dodge, normal;
    background-clip: text;
}

// .playbutton_small_player {
//     padding: 4px !important;
//     border: 3px solid white !important;
// }

.playbutton_small_player {
    --border-width: 2px;
    padding: 4px !important;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    font-family: Lato, sans-serif;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: white;
    background: #222;
    border-radius: var(--border-width);

    &::after {
        position: absolute;
        content: "";
        top: calc(-1 * var(--border-width));
        left: calc(-1 * var(--border-width));
        z-index: -1;
        width: calc(100% + var(--border-width) * 2);
        height: calc(100% + var(--border-width) * 2);
        background: linear-gradient(60deg,
                hsl(224, 85%, 66%),
                hsl(269, 85%, 66%),
                hsl(314, 85%, 66%),
                hsl(359, 85%, 66%),
                hsl(44, 85%, 66%),
                hsl(89, 85%, 66%),
                hsl(134, 85%, 66%),
                hsl(179, 85%, 66%));
        background-size: 300% 300%;
        background-position: 0 50%;
        border-radius: calc(30 * var(--border-width));
    }
}

.playbutton_small_player_small {
    width: 25px;
    height: 25px;
}

.play_animate {
    &::after {
        animation: moveGradient 4s alternate infinite;
    }
}

@keyframes moveGradient {
    50% {
        background-position: 100% 50%;
    }
}

///////////////////Weather

.weather-img {
    object-position: center;
    right: 10px;
    box-shadow: "0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)";
}

.dropshadow {
    filter: drop-shadow(10px 0 5px red);
}

.sunset {
    background: radial-gradient(ellipse farthest-side at 76% 77%,
            rgba(245, 228, 212, 0.25) 4%,
            rgba(255, 255, 255, 0) calc(4% + 1px)),
        radial-gradient(circle at 76% 40%, #fef6ec 4%, rgba(255, 255, 255, 0) 4.18%),
        linear-gradient(135deg, #ff0000 0%, #000036 100%),
        radial-gradient(ellipse at 28% 0%, #ffcfac 0%, rgba(98, 149, 144, 0.5) 100%),
        linear-gradient(180deg, #cd6e8a 0%, #f5eab0 69%, #d6c8a2 70%, #a2758d 100%);
    background-blend-mode: normal, normal, screen, overlay, normal;
}

.sunrise {
    background: radial-gradient(ellipse farthest-side at 76% 77%,
            rgba(245, 228, 212, 0.25) 4%,
            rgba(255, 255, 255, 0) calc(4% + 1px)),
        radial-gradient(circle at 76% 40%, #fef6ec 4%, rgba(255, 255, 255, 0) 4.18%),
        linear-gradient(135deg, #ff0000 0%, #000036 100%),
        radial-gradient(ellipse at 28% 0%, #d00 0%, rgba(98, 149, 144, 0.5) 100%),
        linear-gradient(180deg, #cd6e8a 0%, #f5eab0 69%, #d6c8a2 70%, #a2758d 100%);
    background-blend-mode: normal, normal, screen, overlay, normal;
}

////////////////Desktop Icons

.desktopIcon {
    border-radius: 14px;
}

///////////Popper

#autocompletraja {
    .MuiPaper-root {
        border-radius: 0;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        background-color: #fff1ff;
        margin-top: 10px;
        border-radius: 14px;
        width: 100%;
    }
}

////////////////////////Table Pagination

.tracklist {
    .MuiToolbar-regular {
        padding: 0px;
        min-height: auto;

        margin-top: 10px;
        margin-bottom: 10px;

        .autocomplete-input {
            input {
                padding: 0px 4px 6px 4px !important;
            }
        }

        .MuiTextField-root {
            padding-left: 0px;
        }

        .MuiInput-underline {
            color: #fff;
            background: #fff1;
            padding-Left: 15;
            margin-left: 8;
            border-bottom-color: none;
            border-radius: 13px;
            padding-left: 10px;
        }

        .MuiInput-underline:before {
            border-bottom: 1px solid #fff1;
            border-radius: 100px;
        }

        .MuiInput-underline:after {
            border-bottom: none;
            border-radius: 100px !important;
        }

        .MuiInput-underline:hover:before {
            border-bottom: 1px solid #fff6;
            border-radius: 100px !important;
        }

        .MuiInputAdornment-root {
            display: none;
        }
    }

    .MuiSvgIcon-root {
        color: #fff !important;
    }

    .MuiPagination-ul>li>button {
        color: #fff;
    }

    .MuiTableCell-root {
        border-bottom: 1px solid #fff1 !important;
    }

    .MuiTableFooter-root .MuiTableCell-root {
        border-bottom: none !important;
    }

    .MuiTableCell-root {
        color: #fff !important;
    }
}

.arjitsongs {

    .MuiSvgIcon-root {
        color: #fff !important;
    }

    .MuiPagination-ul>li>button {
        color: #fff;
    }

    .MuiTableCell-root {
        border-bottom: 1px solid #fff1 !important;
    }

    .MuiTableFooter-root .MuiTableCell-root {
        border-bottom: none !important;
    }

    .MuiTableCell-root {
        color: #fff !important;
    }
}

.mainhead {
    background-color: #fff1 !important;
    border: 2px solid #fff1;
    color: #fff !important;
    border-radius: 14px !important;
    padding: 20px;
    // background-image: url(./assets/images/cubes.png)
}

.gitabody {
    background-color: #fff1 !important;
    border: 2px solid #fff1;
    color: #fff !important;
    border-radius: 14px !important;
    background-image: url(./assets/images/cubes.png);

    .MuiCardContent-root:last-child {
        padding-bottom: 10px;
    }
}

.gitacard {
    background-color: #fff1 !important;
    border: 2px solid #fff1;
    color: #fff !important;
    border-radius: 14px !important;
    padding: 20px;
    margin-bottom: 20px;

    .MuiCardContent-root:last-child {
        padding-bottom: 10px;
    }
}

.rajapolavarapu {
    position: absolute;
    width: 200px;
    object-fit: cover;
    margin-top: 24px;
    object-position: center;
    bottom: 0;
    right: 8px;
}

.description {
    margin-top: 10px;
    font-size: 14px;
    margin-top: 8px;
    font-family: var(--body-font);
    font-weight: 400;
    line-height: 1.7em;
}

@keyframes boxShadow {
    50% {
        box-shadow:
            inset 0 0 5px #fff,
            inset 2px 0 5px #f0f,
            inset -2px 0 5px #0ff,
            inset 2px 0 10px #f0f,
            inset -2px 0 10px #0ff,
            0 0 5px #fff,
            -1px 0 5px #f0f,
            1px 0 5px #0ff;
    }
}

#autocompletrajaRadio {
    .MuiPaper-root {
        border-radius: 0;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        margin-top: 4px;
        border-radius: 14px;
        background-color: none;
        background: #FFFEF2;
        border: 5px solid #fff1;
    }
}

/////////////Play Buttons

.playing {
    box-shadow:
        inset 0 0 5px #fff,
        inset 2px 0 5px #f0f,
        inset -2px 0 5px #0ff,
        inset 2px 0 10px #f0f,
        inset -2px 0 10px #0ff,
        0 0 5px #fff,
        -1px 0 5px #f0f,
        1px 0 5px #0ff !important;
    border: none !important;
}

#canvas_id,
#canvas_id_mini {
    position: absolute;
    bottom: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 100px;
    left: 1px;
    right: auto;
}

/////////////Search Bar

.search-bar {
    height: 40px;
    display: flex;
    width: 100%;
    max-width: 400px;
    padding-left: 16px;
    border-radius: 4px;
}

.search-bar input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: var(--search-bg);
    border-radius: 4px;
    font-family: var(--body-font);
    font-size: 15px;
    font-weight: 500;
    padding: 0 20px 0 40px;
    box-shadow: 0 0 0 2px rgba(134, 140, 160, 0.02);
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23717790c7'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 16px 48%;
    color: var(--theme-color);
}

.search-bar input::-moz-placeholder {
    font-family: var(--body-font);
    color: var(--inactive-color);
    font-size: 15px;
    font-weight: 500;
}

.search-bar input:-ms-input-placeholder {
    font-family: var(--body-font);
    color: var(--inactive-color);
    font-size: 15px;
    font-weight: 500;
}

.search-bar input::placeholder {
    font-family: var(--body-font);
    color: var(--inactive-color);
    font-size: 15px;
    font-weight: 500;
}


//////////////Arjit Sing

.arjitheader {
    background: url(./assets/images/arjit_2.png) #9297b321 no-repeat;
    width: 100%;
    height: 200px;
    background-position: bottom right;
    background-size: auto 200px;
}


////////////Flip Cards


.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    background-color: #bbb;
    color: black;
}

.flip-card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);
}


// equalizer equalizer--pause

.equalizer {
    filter: drop-shadow(inset 0 0 5px #fff,
            inset 2px 0 5px #f0f,
            inset -2px 0 5px #0ff,
            inset 2px 0 10px #f0f,
            inset -2px 0 10px #0ff,
            0 0 5px #fff,
            -1px 0 5px #f0f,
            1px 0 5px #0ff);
    height: 40px;
    width: 40px;
    display: inline-block;
    vertical-align: top
}

.equalizer line {
    stroke-dasharray: 20
}

.equalizer line:nth-of-type(1) {
    animation: frequency 1s linear infinite
}

.equalizer line:nth-of-type(2) {
    animation: resonance 1s linear infinite
}

.equalizer line:nth-of-type(3) {
    animation: gain 1s linear infinite
}

.equalizer line:nth-of-type(4) {
    animation: slope 1s linear infinite
}

.equalizer.equalizer--pause line {
    animation-play-state: paused !important
}


//////////Animations

@keyframes frequency {
    0% {
        stroke-dashoffset: 13
    }

    16% {
        stroke-dashoffset: 4
    }

    32% {
        stroke-dashoffset: 10
    }

    48% {
        stroke-dashoffset: 9
    }

    64% {
        stroke-dashoffset: 19
    }

    80% {
        stroke-dashoffset: 7
    }

    100% {
        stroke-dashoffset: 13
    }
}

@keyframes resonance {
    0% {
        stroke-dashoffset: 0
    }

    16% {
        stroke-dashoffset: 10
    }

    32% {
        stroke-dashoffset: 13
    }

    48% {
        stroke-dashoffset: 5
    }

    64% {
        stroke-dashoffset: 15
    }

    80% {
        stroke-dashoffset: 13
    }

    100% {
        stroke-dashoffset: 0
    }
}

@keyframes gain {
    0% {
        stroke-dashoffset: 9
    }

    16% {
        stroke-dashoffset: 14
    }

    32% {
        stroke-dashoffset: 8
    }

    48% {
        stroke-dashoffset: 13
    }

    64% {
        stroke-dashoffset: 4
    }

    80% {
        stroke-dashoffset: 17
    }

    100% {
        stroke-dashoffset: 9
    }
}

@keyframes slope {
    0% {
        stroke-dashoffset: 16
    }

    16% {
        stroke-dashoffset: 6
    }

    32% {
        stroke-dashoffset: 18
    }

    48% {
        stroke-dashoffset: 8
    }

    64% {
        stroke-dashoffset: 10
    }

    80% {
        stroke-dashoffset: 4
    }

    100% {
        stroke-dashoffset: 16
    }
}

////////////


.card-body {
    flex: 1 1 auto;
    padding-top: 1.25rem;
}


.image-lg,
.image-lg img {
    height: 9rem;
    width: 9rem;
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.p-3 {
    padding: 1rem !important;
}

.border-1 {
    border-width: 1px !important;
}

.border {
    border: 1px solid rgba(209, 213, 219, .3) !important;
}

img {
    max-width: 100%;
}

img,
svg {
    vertical-align: middle;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.card-title {
    margin-bottom: 0.875rem;
}

.h5,
h5 {
    font-size: 1.25rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.3;
    margin-bottom: 0.5rem;
    margin-top: 0;
}


html.dark .text-muted {
    color: #fff9;
}

.card-subtitle,
.card-text:last-child {
    margin-bottom: 0;
}

.text-body,
.text-muted {
    color: #fff9
}

.p,
ol li,
p,
ul li {
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
}

p {
    margin-bottom: 1rem;
    margin-top: 0;
}

*,
:after,
:before {
    box-sizing: border-box;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.align-items-center {
    align-items: center !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

.btn-group-sm>.btn,
.btn-sm {
    border-radius: 0.375rem;
    font-size: .875rem;
    padding: 0.375rem 0.75rem;
}

.btn-primary {
    background-color: #2ea44f;
    background-image: var(--bs-gradient);
    border-color: #2ea44f;
    box-shadow: 0 1px 1px rgb(18 21 26 / 8%);
    color: #fff;
}

.btn,
.btn:hover {
    color: #4b5663;
}

.btn {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    padding: 0.5rem 1rem;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
}

a {
    font-weight: 500;
}

.text-action,
a {
    color: #0af;
    transition: none;
}

a,
a:hover {
    text-decoration: none;
}

a {
    color: #0af;
}


.centercard {
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translateX(-50%);
}

.arjitplayer {
    background: linear-gradient(45deg, var(--active-color) 20%, transparent 100%) no-repeat;
}

.arjitplayer.playing {
    animation: colorChange 20s alternate infinite;
}

@keyframes colorChange {
    from {
        filter: hue-rotate(0deg);
    }

    to {
        filter: hue-rotate(360deg);
    }
}

.frostedglass {
    position: absolute;
    height: 100%;
    width: 100%;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(5px);
    z-index: -1;
}