.loading01 {
    span {
        animation: loading01 1.4s infinite alternate;

        @for $i from 0 through 6 {
            &:nth-child(#{$i+1}) {
                animation-delay: #{$i*.1}s;
            }
        }
    }
}

@keyframes loading01 {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.loading02 {
    span {
        animation: loading02 1.2s infinite alternate;

        @for $i from 1 through 6 {
            &:nth-child(#{$i+1}) {
                animation-delay: #{$i*.2}s;
            }
        }
    }
}

@keyframes loading02 {
    0% {
        filter: blur(0);
        opacity: 1;
    }

    100% {
        filter: blur(5px);
        opacity: .2;
    }
}

.loading03 {
    span {
        margin: 0 -.075em;
        animation: loading03 .7s infinite alternate;

        @for $i from 1 through 6 {
            &:nth-child(#{$i+1}) {
                animation-delay: #{$i*.1}s;
            }
        }
    }
}

@keyframes loading03 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(.8);
    }
}

.loading04 {
    span {
        animation: loading04 .7s infinite;

        @for $i from 1 through 6 {
            &:nth-child(#{$i+1}) {
                animation-delay: #{$i*.1}s;
            }
        }
    }
}

@keyframes loading04 {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(15px);
    }
}

.loading05 {
    perspective: 1000px;

    span {
        transform-origin: 50% 50% -25px;
        transform-style: preserve-3d;
        animation: loading05 1.6s infinite;

        @for $i from 1 through 6 {
            &:nth-child(#{$i+1}) {
                animation-delay: #{$i*.1}s;
            }
        }
    }
}

@keyframes loading05 {
    0% {
        transform: rotateX(-360deg);
    }

    70% {
        transform: rotateX(0);
    }
}

.loading06 {
    span {
        position: relative;
        color: rgba(#000, .2);

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: attr(data-text);
            color: #fff;
            opacity: 0;
            transform: rotateY(-90deg);
            animation: loading06 4s infinite;
        }

        @for $i from 1 through 6 {
            &:nth-child(#{$i+1})::after {
                animation-delay: #{$i*.2}s;
            }
        }
    }
}

@keyframes loading06 {

    0%,
    75%,
    100% {
        transform: rotateY(-90deg);
        opacity: 0;
    }

    25%,
    50% {
        transform: rotateY(0);
        opacity: 1;
    }
}

.loading07 {
    span {
        position: relative;
        color: rgba(#000, .2);

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: attr(data-text);
            color: #fff;
            opacity: 0;
            transform: scale(1.5);
            animation: loading07 3s infinite;
        }

        @for $i from 1 through 6 {
            &:nth-child(#{$i+1})::after {
                animation-delay: #{$i*.1}s;
            }
        }
    }
}

@keyframes loading07 {

    0%,
    75%,
    100% {
        transform: scale(1.5);
        opacity: 0;
    }

    25%,
    50% {
        transform: scale(1);
        opacity: 1;
    }
}

.loading08 {
    span {
        position: relative;
        color: rgba(#000, .2);
        animation: loading08-parent 5s infinite;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: attr(data-text);
            color: #fff;
            opacity: 0;
            animation: loading08-child 5s infinite;
        }

        @for $i from 1 through 6 {
            &:nth-child(#{$i+1})::after {
                animation-delay: #{$i*.2}s;
            }
        }
    }
}

@keyframes loading08-parent {

    0%,
    35%,
    100% {
        color: rgba(#000, .2);
    }

    60%,
    80% {
        color: #fff;
    }
}

@keyframes loading08-child {
    0% {
        opacity: 1;
    }

    25%,
    100% {
        opacity: 0;
    }
}